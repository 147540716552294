.logo-container {
  z-index: 0;
  width: 400px;
  height: 609px;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 15%;
  bottom: 0;
  left: auto;
  margin: auto;

  svg {
    width: 100%;
    height: auto;
    bottom: 0;
    transform: rotateZ(30deg) !important;
  }

  .solid-logo {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 100%;
    opacity: 0;
    transform: rotateZ(30deg) !important;
    z-index: 1;
  }
}

.svg-container {
  stroke: #ffd700;
  stroke-width: 10;
}

.svg-container {
  stroke: #ffd700;
  stroke-width: 10;
}

.home-img{
  min-width: 330px;
  width: 530px;
  height: 530px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  background: rgba(0, 0, 0, 0.2) ;
  position: relative;
  left: 60%;
  margin-top: 8%;
}

.home-img::before{
  content: '';
  position: absolute;
  inset: -10px 140px;
  background: linear-gradient(315deg, #00ccff,#ffd700);
  transition: 0.5s;
  animation: animate 4s linear infinite;
}

.home-img:hover::before{
  inset: -20px 0;
}
@keyframes animate {
  0%{
      transform: rotate(0deg);
  }
  0%{
      transform: rotate(360deg);
  }
}

.home-img::after{
  content: '';
  position: absolute;
  inset: 6px;
  background: rgb(22, 32, 82);
  border-radius: 50%;
  z-index: 1;
}
.img-box{
  position: absolute;
  inset: 30px;
  border: 6px solid rgba(0, 0, 0, 0.2);
  z-index: 3;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.img-box img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  object-fit: cover;
  object-position: top;
  transition: 0.5s;
  pointer-events: none;
  z-index: 5;
}

.home-img:hover .img-box img {
  opacity: 0;
}
.img-box h2{
  position: relative;
  color: #fff;
  font-size: 1.4rem;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
}

.img-box h2 span{
  font-weight: 300;
  font-size: 0.9rem;
  
}

.img-box .social a{
  color: #fff;
  font-size: 1.5rem;
  margin: 0 7px;
  transition: 0.3s;
}

.img-box .social a:hover{
  text-shadow: 0 0 10px #fff;
}

.img-box .hire-me{
  text-decoration: none;
  position: relative;
  margin-top: 10px;
  padding: 6px 20px;
  background: #fff;
  color: #000;
  border-radius: 25px;
  font-weight: 500;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  transition: 0.5s;
}

.img-box .hire-me:hover {
  letter-spacing: 0.2rem;
  box-shadow: 0 0 10px #fff;
}


@media only screen and (max-width:600px){
  .home-img {
    width: 230px;
    height: 330px;
    margin-left: -280px;
    margin-top: 100px;
  }
}